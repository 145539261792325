import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b4c9652"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-avatar" }
const _hoisted_2 = { class: "userinfo" }
const _hoisted_3 = { class: "name mr-left-small" }
const _hoisted_4 = { class: "auth mr-left-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, { onCommand: _ctx.handleCommand }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, { command: "loginout" }, {
            default: _withCtx(() => [
              _createTextVNode("退出登录")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_avatar, {
          src: _ctx.userInfo.avatar
        }, null, 8, ["src"]),
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.userInfo.name), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.userType), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onCommand"]))
}