import axios from "@/utils/axios";
import { setCookie, getCookie, delCookie } from "@/utils/cookie";
import router from "@/router";

const state = {
  token: "",
  userInfo: {},
};
const mutations = {
  SET_USER_INFO: (state, { token, userInfo }) => {
    setCookie("token", token);
    state.token = token;
    state.userInfo = userInfo;
  },
  LOGIN_OUT: (state) => {
    delCookie("token");
    state.token = "";
    state.userInfo = {};
  },
  SET_STATE: (state, data) => {
    Object.keys(data).forEach((item) => (state[item] = data[item]));
  },
};
const actions = {
  // 用户登录
  async userLogin({ commit }, params = {}) {
    const data = await axios({
      url: "/admin/login",
      method: "post",
      data: params,
    });
    commit("SET_USER_INFO", data);
    router.push({ path: "/manage" });
  },
  // 验证登录
  async checkLogin({ commit }) {
    const token = getCookie("token");
    if (!token) {
      router.push({ name: "login" });
      return;
    }
    const data = await axios({
      url: "/admin/userInfo",
      method: "get",
    });
    commit("SET_USER_INFO", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
