import axios from "@/utils/axios";

const state = {};
const mutations = {
  SET_STATE: (state, data) => {
    Object.keys(data).forEach((item) => (state[item] = data[item]));
  },
};
const actions = {
  async getUserList(_, params = {}) {
    return await axios({
      url: "/user/getlist",
      method: "get",
      data: params,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
