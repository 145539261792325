
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

/**
 * 导航栏用户头像组件，包含退出和跳转功能
 * @Author yuanyexu
 * @Date 2021-08-03
 */
export default defineComponent({
  name: "Avatar_",
  setup() {
    const store = useStore();
    const router = useRouter();
    const userInfo = computed(() => store.getters.userInfo);
    const userType = computed(() => store.getters.userType);
    const handleCommand = (command) => {
      switch (command) {
        case "loginout":
          store.commit("user/LOGIN_OUT");
          router.push({ name: "login" });
          break;
        default:
          break;
      }
    };
    return {
      userInfo,
      userType,
      handleCommand,
    };
  },
});
