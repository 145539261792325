import { createRouter, createWebHistory, RouterOptions } from "vue-router";
import store from "@/store";
import { start, stop } from "@/libs/nprogress";
import manage from "./modules/manage";
import { routes as page } from "./pages";

// 路由未登录放行白名单
const routerNameWhiteList = [
  "login",
  "index",
  "test",
  "chatRoom",
  "page-index",
  "page-order",
  "page-alipay",
];
const routes = [
  {
    path: "/",
    redirect: "/manage",
  },
  {
    path: "/chatRoom",
    name: "chatRoom",
    component: () => import("@/views/chatRoom/index.vue"),
    redirect: "/chatRoom/home",
    meta: { hidden: true, title: "聊天室" },
    children: [
      {
        path: "login",
        name: "chatRoom-login",
        meta: { hidden: true, title: "登录-聊天室" },
        component: () => import("@/views/chatRoom/login.vue"),
      },
      {
        path: "home",
        name: "chatRoom-home",
        meta: { hidden: true, title: "主页-聊天室" },
        component: () => import("@/views/chatRoom/home.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: { hidden: true, title: "系统登陆" },
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: { hidden: true },
    component: () => import("@/views/test/index.vue"),
  },
  manage,
  ...page,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      el: "#app",
      top: 0,
      left: 0,
      behavior: "smooth",
    };
  },
} as RouterOptions);

router.beforeEach(async (to: any, from: any, next) => {
  const userToken = store.getters.userToken;
  const userAuth = to.meta.requireAuth;
  const userName = store.getters.userInfo.name;
  const userType = store.getters.userInfo.auth;
  window.document.title = to.meta.title || process.env.VUE_APP_SITE_NAME;
  const inWhiteList = routerNameWhiteList.some(
    (item) => to.name && to.name.includes(item)
  );
  if (to.name === "login" && userToken) {
    next("/manage");
  }
  if (!userName && !inWhiteList) {
    await store.dispatch("user/checkLogin");
  }
  if (userAuth && !userAuth.includes(userType)) {
    next("/manage/error/403");
  }
  next();
});
router.beforeEach((to, from, next) => {
  start();
  next();
});
router.afterEach((to, from) => {
  stop();
});

export default router;
