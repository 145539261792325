import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4aebd4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-wrapper" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_MainContent = _resolveComponent("MainContent")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createVNode(_component_Sidebar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Navbar),
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_MainContent),
        _createVNode(_component_MainFooter)
      ])
    ])
  ]))
}