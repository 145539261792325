export default [
  {
    name: "article",
    path: "article",
    redirect: "/manage/article/list",
    meta: {
      title: "文章管理",
      iconName: "Message",
      noAuth: [],
    },
    children: [
      {
        name: "article-list",
        path: "list",
        meta: { title: "文章列表", iconName: "List", noAuth: [] },
        component: () => import("@/views/article/list.vue"),
      },
      {
        name: "article-category",
        path: "category",
        meta: { title: "文章分类", iconName: "Collection", noAuth: [] },
        component: () => import("@/views/article/category.vue"),
      },
      {
        name: "article-tags",
        path: "tags",
        meta: { title: "文章标签", iconName: "Paperclip", noAuth: [] },
        component: () => import("@/views/article/tags.vue"),
      },
      {
        name: "article-comments",
        path: "comments",
        meta: { title: "文章留言", iconName: "Postcard", noAuth: [] },
        component: () => import("@/views/article/comments.vue"),
      },
      {
        name: "article-list-create",
        path: "create",
        meta: { title: "写文章", iconName: "Tickets", hidden: true },
        component: () => import("@/views/article/create.vue"),
      },
      {
        name: "article-list-update",
        path: "update",
        meta: { title: "修改文章", iconName: "Tickets", hidden: true },
        component: () => import("@/views/article/create.vue"),
      },
      {
        name: "article-list-detail",
        path: "detail",
        meta: { title: "文章详情", iconName: "Tickets", hidden: true },
        component: () => import("@/views/article/detail.vue"),
      },
    ],
  },
  // {
  //   name: "user",
  //   path: "userManage",
  //   redirect: "/manage/userManage/list",
  //   meta: {
  //     title: "用户管理",
  //     iconName: "Avatar",
  //     noAuth: [],
  //   },
  //   children: [
  //     {
  //       name: "user-list",
  //       path: "list",
  //       component: () => import("@/views/user/index.vue"),
  //       meta: {
  //         title: "用户列表",
  //         iconName: "List",
  //         noAuth: [],
  //       },
  //     },
  //     {
  //       name: "user-list-detail",
  //       path: "detail",
  //       component: () => import("@/views/user/detail.vue"),
  //       meta: {
  //         title: "用户详情",
  //         iconName: "User",
  //         hidden: true,
  //         noAuth: [],
  //       },
  //     },
  //   ],
  // },
  {
    name: "picture",
    path: "picture",
    redirect: "/manage/picture/list",
    meta: {
      title: "图集管理",
      iconType: "iconfont",
      iconName: "icon-xiangce",
      noAuth: [],
    },
    children: [
      {
        name: "picture-list",
        path: "list",
        component: () => import("@/views/picture/index.vue"),
        meta: {
          title: "图集列表",
          iconName: "Menu",
          noAuth: [],
        },
      },
      {
        name: "picture-create",
        path: "create",
        component: () => import("@/views/picture/create.vue"),
        meta: {
          title: "创建图集",
          iconName: "Menu",
          noAuth: [],
          hidden: true,
        },
      },
      {
        name: "picture-update",
        path: "update",
        component: () => import("@/views/picture/create.vue"),
        meta: {
          title: "修改图集",
          iconName: "Menu",
          noAuth: [],
          hidden: true,
        },
      },
      {
        name: "picture-detail",
        path: "detail",
        component: () => import("@/views/picture/create.vue"),
        meta: {
          title: "图集详情",
          iconName: "Menu",
          noAuth: [],
          hidden: true,
        },
      },
    ],
  },
];
