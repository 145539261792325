
import NavbarAvatar from "./NavbarAvatar/index.vue";
import { useStore } from "vuex";
import { defineComponent, computed } from "vue";
/**
 * 布局右侧导航栏，具有侧边栏菜单控制按钮
 * @Author yuanyexu
 * @Date 2021-08-03
 */
export default defineComponent({
  name: "Nav-Bar",
  components: { NavbarAvatar },
  setup() {
    const store = useStore();
    const isCollapse = computed(() => store.state.app.isCollapse);
    const changeNavCollapse = (state) => {
      store.commit("app/SET_STATE", {
        isCollapse: state,
      });
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("--sidebarwidth", state ? "6.25rem" : "16.25rem");
    };
    return {
      isCollapse,
      changeNavCollapse,
    };
  },
});
