
import { defineComponent, computed, watch, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { SITE_NAME } from "@/config";
/**
 * 侧边栏菜单组件
 * @Author yuanyexu
 * @Date 2021-08-03
 */
export default defineComponent({
  name: "Side-Bar",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const routes = router.getRoutes();
    const activeMenuCpd = ref("");
    const isCollapse = computed(() => store.state.app.isCollapse);

    const routesCpd: any = computed(() => {
      let manageRoutes = routes.find(
        (item) => item.name === "manage"
      )?.children;
      const userType = store.getters.userInfo.auth;
      const filterAuth = (item) => {
        const noAuth = (item?.meta?.noAuth || []) as Array<number>;
        if (item?.meta?.hidden) {
          return false;
        }
        if (noAuth.includes(userType)) {
          return false;
        }
        return true;
      };
      // 一级菜单过滤
      manageRoutes = manageRoutes.filter((item) => filterAuth(item));
      // 二级菜单过滤
      manageRoutes.forEach((item, index) => {
        if (item.children) {
          manageRoutes[index].children = manageRoutes[index].children.filter(
            (item) => filterAuth(item)
          );
        }
      });
      return manageRoutes;
    });
    const handleselect = (name: string) => {
      router.push({ name });
    };
    const getActiveMenu = () => {
      // 路由name为goodsmanage-config-abcd, 默认选中goodsmanage-config
      activeMenuCpd.value = route.name
        .toString()
        .split("-")
        .slice(0, 2)
        .join("-");
    };
    onBeforeMount(() => {
      getActiveMenu();
    });
    return {
      SITE_NAME,
      isCollapse,
      routesCpd,
      activeMenuCpd,
      handleselect,
    };
  },
});
