
import { SITE_NAME } from "@/config";
import { defineComponent } from "vue";

/**
 * 布局底部页脚组件
 * @Author yuanyexu
 * @Date 2021-08-03
 */
export default defineComponent({
  name: "MainFooter",
  setup() {
    return {
      // 年份
      year: new Date().getFullYear(),
      SITE_NAME,
    };
  },
});
