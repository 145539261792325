import { USER_TYPES } from "@/config";
import { getCookie } from "@/utils/cookie";

export function userToken(state: any) {
  return state.user.token || getCookie("token");
}
export function userInfo(state: any) {
  return state.user.userInfo;
}
export function isAdmin(state) {
  return state.user.userInfo.auth === 1;
}
export function userType(state: any) {
  return USER_TYPES[state.user.userInfo.auth] || "未知权限";
}
