
import { computed, defineComponent, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { Sidebar, Navbar, MainContent, MainFooter } from "./components";
/**
 * 布局容器组件
 * @Author yuanyexu
 * @Date 2021-08-03
 */
export default defineComponent({
  name: "Layout_",
  components: { Sidebar, Navbar, MainContent, MainFooter },
  setup() {
    /**
     * Vuex Store对象
     * @type {object}
     */
    const store = useStore();

    /**
     * 监听屏幕改变事件，设置侧边栏收起展开状态
     */
    const onResize = () => {
      // TODO:
    };

    onBeforeMount(() => {
      window.addEventListener("resize", onResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });

    /**
     * 计算属性：获取侧边栏sidebar对象
     * @returns {object} 侧边栏sidebar对象
     */
    const sidebar = computed(() => {
      return store.state.sidebar;
    });

    return { sidebar };
  },
});
