import axios from "@/utils/axios";

const state = {
  tags: [],
  categorys: [],
};
const mutations = {
  SET_STATE: (state, data) => {
    Object.keys(data).forEach((item) => (state[item] = data[item]));
  },
};
const actions = {
  async getConfig({ commit }, params) {
    const data = await axios({
      url: "/article/getConfig",
      method: "get",
      params,
    });
    commit("SET_STATE", data);
  },
  async saveConfig(_, params) {
    return await axios({
      url: "/article/saveConfig",
      method: "POST",
      data: params,
    });
  },
  async delectConfig(_, params) {
    return await axios({
      url: "/article/delectConfig",
      method: "POST",
      data: params,
    });
  },
  async updateConfig(_, params) {
    return await axios({
      url: "/article/updateConfig",
      method: "POST",
      data: params,
    });
  },
  async saveContent(_, params) {
    return await axios({
      url: "/article/save",
      method: "POST",
      data: params,
    });
  },
  async updateContent(_, params) {
    return await axios({
      url: "/article/update",
      method: "POST",
      data: params,
    });
  },
  async delectRow(_, params) {
    return await axios({
      url: "/article/delectRow",
      method: "POST",
      data: params,
    });
  },
  async getlist(_, params) {
    return await axios({
      url: "/article/getlist",
      method: "GET",
      params,
    });
  },
  async getDetail(_, params) {
    return await axios({
      url: "/article/getDetail",
      method: "GET",
      params,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
