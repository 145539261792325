import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a8f1d35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "nav-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_navbar_avatar = _resolveComponent("navbar-avatar")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["navCollapse", { active: _ctx.isCollapse }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeNavCollapse(!_ctx.isCollapse)))
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_Fold)
            ]),
            _: 1
          })
        ], 2)
      ]),
      _createVNode(_component_navbar_avatar, { class: "avatar-container mr-right-standard" })
    ])
  ]))
}