import Layout from "@/layout/index.vue";
import article from "./article";

export default {
  path: "/manage",
  name: "manage",
  component: Layout,
  redirect: "/manage/dashboard",
  children: [
    {
      name: "dashboard",
      path: "dashboard",
      component: () => import("@/views/dashboard/index.vue"),
      meta: {
        title: "系统首页",
        iconName: "Odometer",
        noAuth: [],
      },
    },
    {
      name: "center",
      path: "center",
      redirect: "/manage/center/list",
      meta: {
        title: "数据中心",
        iconName: "Coin",
        noAuth: [],
      },
      children: [
        {
          name: "center-fangwen",
          path: "fangwen",
          meta: {
            title: "访问数据",
            iconName: "Notification",
            noAuth: [],
          },
          component: () => import("@/views/center/entry.vue"),
        },
        {
          name: "center-dingdan",
          path: "dingdan",
          meta: {
            title: "订单数据",
            iconName: "Notification",
            noAuth: [],
          },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "center-jiankong",
          path: "jiankong",
          meta: { title: "监控数据", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/jiankong.vue"),
        },
        {
          name: "center-chongzhi",
          path: "chongzhi",
          meta: { title: "充值记录", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/chongzhi.vue"),
        },
        {
          name: "center-dailishouyi",
          path: "dailishouyi",
          meta: { title: "代理收益", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/dailishouyi.vue"),
        },
        {
          name: "center-pingtaishouyi",
          path: "pingtaishouyi",
          meta: { title: "平台收益", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/pingtaishouyi.vue"),
        },
      ],
    },
    {
      name: "fenxiao",
      path: "fenxiao",
      redirect: "/manage/fenxiao/list",
      meta: {
        title: "分销管理",
        iconType: "iconfont",
        iconName: "icon-huiyuan",
        noAuth: [],
      },
      children: [
        {
          name: "fenxiao-create",
          path: "create",
          meta: { title: "新增代理", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/fenxiao/create.vue"),
        },
        {
          name: "fenxiao-update",
          path: "update",
          meta: {
            hidden: true,
            title: "代理列表-修改代理",
            iconName: "Notification",
            noAuth: [],
          },
          component: () => import("@/views/fenxiao/create.vue"),
        },
        {
          name: "fenxiao-list",
          path: "list",
          meta: { title: "代理列表", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/fenxiao/list.vue"),
        },
      ],
    },
    {
      name: "goods",
      path: "goods",
      redirect: "/manage/goods/center",
      meta: {
        title: "商品管理",
        iconName: "Goods",
        noAuth: [],
      },
      children: [
        {
          name: "goods-muban",
          path: "muban",
          meta: { title: "商品模板", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/goods/muban.vue"),
        },
        {
          name: "goods-create",
          path: "create",
          meta: { title: "新建商品", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/goods/create.vue"),
        },
        {
          name: "goods-list-update",
          path: "update",
          meta: {
            hidden: true,
            title: "修改商品",
            iconName: "Notification",
            noAuth: [],
          },
          component: () => import("@/views/goods/create.vue"),
        },
        {
          name: "goods-list",
          path: "list",
          meta: { title: "商品列表", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/goods/center.vue"),
        },
        {
          name: "goods-shenhe",
          path: "shenhe",
          meta: { title: "商品审核", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/goods/verify.vue"),
        },
        {
          name: "goods-orders",
          path: "orders",
          meta: { title: "商品订单", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/goods/orders.vue"),
        },
      ],
    },
    {
      name: "pay",
      path: "pay",
      meta: { title: "支付配置", iconName: "Wallet", noAuth: [] },
      component: () => import("@/views/pay/list.vue"),
    },
    {
      name: "pay-edit",
      path: "payEdit",
      meta: {
        title: "支付配置-修改",
        iconName: "Collection",
        noAuth: [],
        hidden: true,
      },
      component: () => import("@/views/pay/edit.vue"),
    },
    {
      name: "yunchu",
      path: "yunchu",
      meta: { title: "云储配置", iconName: "SetUp", noAuth: [] },
      component: () => import("@/views/yunchu/list.vue"),
    },
    {
      name: "yunchu-edit",
      path: "yunchuEdit",
      meta: {
        title: "云储配置-修改",
        iconName: "Notification",
        noAuth: [],
        hidden: true,
      },
      component: () => import("@/views/yunchu/edit.vue"),
    },
    {
      name: "tixianmanage",
      path: "tixianmanage",
      redirect: "/manage/tixianmanage/center",
      meta: {
        title: "提现中心",
        iconType: "iconfont",
        iconName: "icon-zhanghuyue",
        noAuth: [],
      },
      children: [
        {
          name: "tixianmanage-dingdan",
          path: "dingdan",
          meta: { title: "订单提现", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "tixianmanage-yongjin",
          path: "yongjin",
          meta: { title: "佣金提现", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "tixianmanage-shenhe",
          path: "shenhe",
          meta: { title: "提现审核", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
      ],
    },
    {
      name: "yuming",
      path: "yuming",
      meta: {
        title: "域名安全",
        iconName: "Connection",
        noAuth: [],
      },
      children: [
        {
          name: "yuming-fanghong",
          path: "fanghong",
          meta: { title: "域名防红", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "yuming-yongjiu",
          path: "yongjiu",
          meta: { title: "永久域名", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "yuming-paohui",
          path: "paohui",
          meta: { title: "炮灰域名", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
      ],
    },
    ...article,
    {
      name: "system",
      path: "system",
      meta: {
        title: "系统管理",
        iconType: "iconfont",
        iconName: "icon-setting",
        noAuth: [],
      },
      children: [
        {
          name: "system-usercenter",
          path: "usercenter",
          meta: { title: "个人中心", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "system-user",
          path: "user",
          component: () => import("@/views/system/user/index.vue"),
          meta: {
            title: "用户管理",
            iconName: "UserFilled",
            noAuth: [],
          },
        },
        {
          name: "system-user-create",
          path: "usercreate",
          component: () => import("@/views/system/user/create.vue"),
          meta: {
            hidden: true,
            title: "用户管理-创建",
            iconName: "Notification",
            noAuth: [],
          },
        },
        {
          name: "system-user-update",
          path: "userupdate",
          component: () => import("@/views/system/user/create.vue"),
          meta: {
            hidden: true,
            title: "用户管理-修改",
            iconName: "Notification",
            noAuth: [],
          },
        },
        {
          name: "system-quanxian",
          path: "quanxian",
          meta: { title: "权限管理", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
        {
          name: "system-caozuorizhi",
          path: "caozuorizhi",
          meta: { title: "操作日志", iconName: "Notification", noAuth: [] },
          component: () => import("@/views/center/orders.vue"),
        },
      ],
    },

    {
      path: "error",
      name: "error",
      redirect: "manage/error/404",
      meta: { hidden: true, title: "error", noAuth: [] },
      children: [
        {
          name: "404",
          path: "404",
          meta: { hidden: true, title: "404", noAuth: [] },
          component: () => import("@/views/error/404.vue"),
        },
        {
          name: "403",
          path: "403",
          meta: { hidden: true, title: "403", noAuth: [] },
          component: () => import("@/views/error/403.vue"),
        },
      ],
    },
    {
      path: ":pathMatch(.*)",
      name: "not-found",
      meta: { hidden: true, title: "not-found", noAuth: [] },
      component: () => import("@/views/error/404.vue"),
    },
  ],
};
