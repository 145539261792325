import axios from "@/utils/axios";

const state = {};
const mutations = {
  SET_STATE: (state, data) => {
    Object.keys(data).forEach((item) => (state[item] = data[item]));
  },
};
const actions = {
  async getPicgroupList(_, params = {}) {
    return await axios({
      url: "/picgroup/getlist",
      method: "get",
      data: params,
    });
  },
  async getPicgroupDetail(_, params = {}) {
    console.log(params);
    return await axios({
      url: "/picgroup/getDetail",
      method: "get",
      params,
    });
  },
  async createPicgroup(_, params = {}) {
    return await axios({
      url: "/picgroup/create",
      method: "post",
      data: params,
    });
  },
  async updatePicgroup(_, params = {}) {
    return await axios({
      url: "/picgroup/update",
      method: "post",
      data: params,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
