import { createStore } from "vuex";
import user from "./modules/user";
import app from "./modules/app";
import wxUser from "./modules/wxUser";
import article from "./modules/article";
import picgroup from "./modules/picgroup";
import * as getters from "./getters";
import * as actions from "./actions";

/**
 * Vuex Store对象
 * @type {object}
 */
export default createStore({
  state: {},
  getters,
  actions,
  modules: {
    app,
    user,
    article,
    wxUser,
    picgroup,
  },
});
