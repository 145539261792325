const SITE_NAME = "流量掘金联盟";
const USER_TYPES = {
  1: "超级管理员",
  2: "代理用户",
  3: "普通用户",
};
/**
 * 默认当前页
 * @type {number}
 */
const DEFAULT_FIRST_PAGE = 1;

/**
 * 默认每页个数
 * @type {number}
 */
const DEFAULT_PAGE_SIZE = 10;

/**
 * 默认分页布局
 * @type {number}
 */
const DEFAULT_PAGE_LAYOUT = "prev, pager, next";
// 正则验证表达式

const REG_EXP = {
  phone: /^[1][3,4,5,7,8][0-9]{9}$/,
  idCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
};
export {
  USER_TYPES,
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_LAYOUT,
  REG_EXP,
  SITE_NAME,
};
