const state = {
  isCollapse: false,
};
const mutations = {
  SET_STATE: (state, data) => {
    Object.keys(data).forEach((item) => (state[item] = data[item]));
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
