import Axios from "axios";
import { ElMessage } from "element-plus";
import { showLoading, hideLoading } from "@/libs/loading";
import { delCookie } from "@/utils/cookie";
import store from "@/store";

export const baseURL = process.env.VUE_APP_BASEURL;

const axios = Axios.create({
  baseURL,
  timeout: 20000,
  headers: {},
});

// 前置拦截器（发起请求之前的拦截）
axios.interceptors.request.use(
  // TODO: 根据项目实际情况来对 response 做处理
  (response) => {
    // @ts-ignore # 忽视本行代码的小错误
    if (!response.headers.noloading) {
      showLoading();
    }
    // @ts-ignore # 忽视本行代码的小错误
    response.headers.authorToken = store.getters.userToken;
    return response;
  },
  (error) => Promise.reject(error)
);

// 后置拦截器（获取到响应时的拦截）
axios.interceptors.response.use(
  (response) => {
    hideLoading();
    const {
      data: { code, msg, data },
    } = response;
    if (code !== 200) {
      const errInfo = `错误: ${code},${msg || "系统错误，请稍后重试!"}`;
      ElMessage.error(errInfo);
      return Promise.reject(errInfo);
    }
    return data;
  },
  (error) => {
    hideLoading();
    if (error.response && error.response.data) {
      const code = error.response.data.code;
      const msg = error.response.data.msg;
      if (code === 401) {
        delCookie("token");
        store.dispatch("user/checkLogin");
        return;
      }
      ElMessage.error(
        `错误: ${code || error.response.status},${
          msg || error.response.statusText
        }`
      );
      console.error("[Axios Error]", error.response);
    } else {
      ElMessage.error(`${error}`);
    }
    return Promise.reject(error);
  }
);

export default axios;
